.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  &--lg {
    width: 100px;
    height: 100px;
  }

  &--xl {
    width: 260px;
    height: 260px;
  }

  &.ss-icon:before {
    position: relative;
    top: 3px;
    @include font-size(1.375rem);
  }
}

.blob-value-2 {
  transform: rotate(30deg);
}
.blob-value-3 {
  transform: rotate(60deg);
}
.blob-value-4 {
  transform: rotate(90deg);
}
.blob-value-5 {
  transform: rotate(180deg);
}
.blob-value-6 {
  transform: rotate(280deg);
}

.blob-icon {
  position: relative;
  top: -70%;
  left: 25% !important;

  @include media-breakpoint-up(lg) {
    left: 0% !important;
  }

}
