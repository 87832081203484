.home-page-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &__blobs-1,
  &__blobs-2 {
    z-index: -1;
    position: absolute;
    width: 500px;
    opacity: .6;
  }

  &__blobs-1 {
    top: 100px;
    left: -250px;
  }

  &__blobs-2 {
    bottom: 100px;
    right: -200px;
  }
}