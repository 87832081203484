.form-btn {
  .form-btn-progress {
    display: none;
  }

  &[disabled] {
    :not(.form-btn-progress) {
      display: none;
    }
    .form-btn-progress {
      display: inline;
    }
  }
}
