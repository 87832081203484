h3, h4, h5, h6 {line-height: 1.15;}

h1.subtitle, h6.subtitle {
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  color: scale-color($color: $secondary, $alpha: -30%);
  margin-bottom: 0.5rem !important;

  .bg-dark & {
    color: scale-color($color: $white, $alpha: -15%);
  }
}

span.highlight {
  color: $secondary;
  position: relative;
  display: inline-block !important;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: -8px;
    background-image: url('underline.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    z-index: 1;

  }
}

.team-image {
  clip-path: polygon(42% 0%,0% 100%,100% 88%);
  max-width: 100%;

  &-shadow {
    filter: drop-shadow(0 6px 16px rgba(0,0,0,.32)) drop-shadow(-50px -30px 0 #E6735C);
    position: relative;
    left: 10%;
    z-index: 2;
  }
}

.header-image {
  left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}

.box {
  border-radius: $border-radius;
  overflow: hidden;
  height: 100%;
  background: white;
  border: 1px solid rgba($black, .16);

  .bg-dark & {
    background: #3A4C5A;
  }

  .bg-mellow-yellow & {
    background: white;
    border: 1px solid rgba($black, .16);
  }
}

.service-illustration {
  width: 100%;
  height: 20rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 3rem;
  margin-bottom: -3rem;
}

.case-study-card {
  height: 100%;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.card-bg-1 {
    background-color: #FAF1C3;
  }
  &.card-bg-2 {
    background-color: #FFD4BF;
  }
  &.card-bg-3 {
    background-color: #CCDDFF;
  }
}
.mw-narrow {
  max-width: 750px !important;
}

.outline-0 {
  outline: none !important;
}

.font-size-lg {
  @include font-size($font-size-lg);
}

.font-size-sm {
  @include font-size($font-size-sm);
}

.opacity-75 { opacity: .75; }

.entry-link {
  color: $black;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    h3, h5 {
      color: $black;
      text-decoration: underline;
      text-decoration-color: $primary;
    }

    p {
      color: $black;
      text-decoration: underline;
      text-decoration-color: $primary;
    }
  }
}

.scroll-text {
  font-size: 3.75rem;
  line-height: 115%;
}

.blockquote, .blockquote p {
  line-height: 1.2 !important;
}

.star {
  color: #F0CA39;
  fill: currentColor;
}

.shadow-gradient {
  transform: translate(0,0) rotate(0deg) skewX(0) skewY(0) scaleX(1) scaleY(1.04);
  transform-origin: bottom right;
  inset: 0;

  @include media-breakpoint-up(sm) {
    transform: translate(0,0) rotate(3deg) skewX(0) skewY(0) scaleX(1) scaleY(1.04);
  }

  &.yellow-orange {
    background: linear-gradient(127deg, #E6735C 0.65%, #FFDB85 85.85%);
  }

  &.purple-pink{
    background: linear-gradient(0deg, #FBC8D4 0%, #9795F0 100%);
  }
}

.card {
  .card-avatar {
    border: $border-width $border-style var(--bs-border-color);
  }

  form {
    .subtitle h6 {
      font-weight: 400;
    }
  }

  .card-title, .card-body {
    h2, h3 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.no-p-margin {
  p:first-child {
    margin-top: 0 !important;
  }
  p:last-child {
    margin-bottom: 0 !important;
  }
}
