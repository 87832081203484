@use 'sass:math';
$calendar-size: 80px;

.blog-article {
  p {
    line-height: 1.25;
  }
}

.blog-header {
  background: $gray-100;
}

.blog-post-thumbnail,
.daily-post-thumbnail {
  height: auto;
  border: 1px solid $gray-300;  
}

.blog-post-thumbnail {
  width: 100%;
  max-width: 100%;
}

.daily-post-thumbnail {
  filter: blur(4px);
  // negative margins so that blurred edges aren't visible
  margin: -4px;
  width: calc(100% + 8px);
  max-width: calc(100% + 8px);
}

.daily-post-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .2);
  color: #fff;

  // center content
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tag {
  background: rgba(white, 0.7);
  text-transform: capitalize;
  border: 1px solid $gray-300;
  border-radius: 1rem;
}

.daily-post-calendar {
  width: $calendar-size;
  text-align: center;
  border-radius: 4px;
  box-shadow: $box-shadow-lg;

  &__month {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background: #F0CA39;
    padding: 4px;
    text-transform: uppercase;
    color: #fff;
    font-size: math.div($calendar-size, 6);
  }

  &__day {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: math.div($calendar-size, 2);
    background: #fff;
    color: $body-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
