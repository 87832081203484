@use 'sass:math';

@mixin rich-text {
  h2, .lead-magnet {
    margin-top: $spacer*5;
    margin-bottom: $spacer*3;
  }

  h3, h4 {
    margin-top: $spacer*2;
    margin-bottom: $spacer;
  }

  h5 {
    text-transform: uppercase;
    opacity: 0.7;
    margin-top: $spacer*2;
  }

  p {
    line-height: $line-height-lg;
  }

  figure {
    margin: 0;

    figcaption {
      margin-top: math.div($spacer, 4);
      @include font-size($font-size-base);
      font-style: italic;
      text-align: center;
      color: $text-muted;
    }
  }
}

@mixin fill-color-variant($parent, $color) {
  #{$parent} {
    fill: $color !important;
  }
}

/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
  position: absolute;
  top:  50%;
  left: 50%;
  width:  $item-size;
  height: $item-size;
  margin: -(math.div($item-size,2));

  $angle: (math.div(360, $item-count));
  $rot: 0;

  @for $i from 1 through $item-count {
    &:nth-of-type(#{$i}) {
      transform:
        rotate($rot * 1deg)
        translate(math.div($circle-size, 2))
        rotate($rot * -1deg);
    }

    $rot: $rot + $angle;
  }
}
