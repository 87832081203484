@use 'sass:math';
.device {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;

  .device-inner {
    width: 100%;
  }

  .device-screen {
    position: absolute;
    overflow: hidden;
    background: theme-color(primary);

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      box-shadow: inset 0 0 1px #000;
    }

    &:before {
      background: url('device-screen.svg') no-repeat;
      background-size: contain;
    }

    img {
      width: 100%;
      min-height: 100%;
    }
  }
}

.device-desktop {
  background-image: url('device-laptop.svg');

  .device-inner {
    padding-bottom: 56%; /* ratio of width */
  }

  .device-screen {
    top: 6%;
    left: 11.2%;
    width: 77.6%;
    height: 80%;
  }
}

.device-tablet {
  background-image: url('device-tablet.svg');

  .device-inner {
    padding-bottom: 74.4%; /* ratio of width */
  }

  .device-screen {
    top: 6%;
    left: 10%;
    width: 80%;
    height: 87%;
  }
}

.device-phone {
  background-image: url('device-phone.svg');

  .device-inner {
    padding-bottom: 193%; /* ratio of width */
  }

  .device-screen {
    top: 13.4%;
    left: 7.4%;
    width: 85.2%;
    height: 73%;
  }
}

.devices {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;

  .device + .device {
    margin-left: math.div($spacer, 2);
  }

  .device-desktop {
    flex: 8;
  }

  .device-tablet {
    flex: 7;
    max-width: 80%;
  }

  .device-phone {
    flex: 2;
    max-width: 40%;
  }
}
