.contact-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .address-section {
    margin-top: auto;
    border-bottom: 4px solid #e7ebed;

    &__content {
      padding-top: $spacer * 3;
      padding-bottom: $spacer * 3;

      background: url('vancouver.svg') no-repeat center bottom;
      background-size: 75% auto;
    }
  }
}
