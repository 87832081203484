.one-ftp-logo {
  max-width: 110px;
}

.canadian-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 0.5rem;

  background: #aa202c;
  color: #fff;

  font-size: $font-size-sm;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  &__leaf {
    width: 1.5rem;
    height: 1.5rem;
    background: url(maple-leaf.svg) no-repeat center center;
  }
}
