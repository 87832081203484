body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.page-section {
  h1 {
    margin-bottom: $spacer*1.5;
  } 

  h2 {
    margin-bottom: $spacer;
  }

  &.page-section-full-height{
    height: 100vh;
  }
  
  .extended-content-container {
    
    @include media-breakpoint-up(lg) {
      margin-right: -200%;
    }
  }

  .extended-content {
      width:100%;
  }
}


