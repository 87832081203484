

// Color system

$primary:      #527ACC;
$primary-dark: #186e97;
$secondary:    #E6735C;
$accent:       #F9DC7C;
$mellow-yellow: #FFFCF5;


// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4),
  7: ($spacer * 7),
  8: ($spacer * 8),
  9: ($spacer * 9),
  15: ($spacer * 15),
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Borders
$border-radius-lg: .6rem;

// Fonts
$font-family-sans-serif: 'IBM Plex Sans', sans-serif;
$font-weight-normal:     400;
$font-weight-bold:       700;

$headings-font-family:   'Work Sans', sans-serif;
$headings-font-weight:   800;
$headings-line-height:  1.2;

$font-size-base:              1.3rem;
$font-size-lg:                1.40rem !default;
$font-size-sm:                1rem;
$h1-font-size:                3.75rem;
$h2-font-size:                3.45rem;
$h3-font-size:                2.00rem;
$h4-font-size:                1.5rem;
$h5-font-size:                $font-size-sm;

$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              1.75;


h4 , h5, h6 {font-family: 'IBM Plex Sans', sans-serif !important; }

$blockquote-font-size:        2rem;
$blockquote-footer-color:    var(--bs-light);

$enable-responsive-font-sizes: true;

$display-font-sizes: (
  1: 4.5rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.5rem,
  6: 2rem
);

// scss-docs-start border-variables
$border-width:                1px !default;
$border-style:                solid !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$display-font-family: 'Work Sans', sans-serif;
$display-font-weight: 800;
$display-line-height: 1;

//links
$link-color: $primary;

// Nav
$navbar-padding-y:                  1.5rem !default;
$navbar-nav-link-padding-x:         1.25rem;

// Alerts
$alert-border-width:                0;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .5rem !default;
$btn-padding-x:               1.5rem !default;
$btn-font-size:               1.1rem !default;

$btn-font-weight:             $font-weight-bold !default;
$btn-border-width:            2px;

$btn-padding-y-sm:            0.3rem;
$btn-padding-x-sm:            1.5rem;
$btn-font-size-sm:            $font-size-sm;

$btn-padding-y-lg:            1.2rem;
$btn-padding-x-lg:            3.1rem;
$btn-font-size-lg:            $font-size-base;

$btn-border-radius:           3rem;
$btn-border-radius-sm:        3rem;
$btn-border-radius-lg:        3rem;

$btn-box-shadow:              none;

// Carousel
$carousel-indicator-active-bg: $secondary;

// Hamburger
$hamburger-types: (spin);
$hamburger-layer-color: var(--bs-body-color);
$hamburger-hover-opacity: 1;

$enable-negative-margins: true;
$enable-shadows: true;

$accordion-padding-y:         1.5rem;
$accordion-bg:                $mellow-yellow;

//card
$card-box-shadow:             0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
$card-border-radius:          2rem;
