.circle-of-icons {
  position: relative;
  width:  300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  > .icon {
    @include on-circle($item-count: 8, $circle-size: 250px, $item-size: 48px);

    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .8;
  }
}
