@font-face {
  font-family: 'helium';
  src: url('fonts/helium.woff2') format('woff2'),
       url('fonts/helium.woff') format('woff');
  font-display: auto;
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'helium';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sm {
  font-size: 28px;
}

.icon-lg {
  font-size: 48px;
}

.icon-xl {
  font-size: 72px;
}

.icon-xxl {
  font-size: 150px;
}

.icon-zip:before {
  content: '\e600';
}
.icon-camera:before {
  content: '\e601';
}
.icon-texttool:before {
  content: '\e602';
}
.icon-text:before {
  content: '\e603';
}
.icon-table:before {
  content: '\e604';
}
.icon-presentation:before {
  content: '\e605';
}
.icon-design:before {
  content: '\e606';
}
.icon-web:before {
  content: '\e607';
}
.icon-gamepad:before {
  content: '\e608';
}
.icon-calculator:before {
  content: '\e609';
}
.icon-keyboard:before {
  content: '\e60a';
}
.icon-desktop:before {
  content: '\e60b';
}
.icon-mobile:before {
  content: '\e60c';
}
.icon-mouse:before {
  content: '\e60d';
}
.icon-watch:before {
  content: '\e60e';
}
.icon-printer:before {
  content: '\e60f';
}
.icon-arrow:before {
  content: '\e610';
}
.icon-coding:before {
  content: '\e611';
}
.icon-quote:before {
  content: '\e612';
}
.icon-trash:before {
  content: '\e613';
}
.icon-magnet:before {
  content: '\e614';
}
.icon-ruler:before {
  content: '\e615';
}
.icon-crop:before {
  content: '\e616';
}
.icon-magic:before {
  content: '\e617';
}
.icon-brush:before {
  content: '\e618';
}
.icon-pentool:before {
  content: '\e619';
}
.icon-hand:before {
  content: '\e61a';
}
.icon-picture:before {
  content: '\e61b';
}
.icon-icecream:before {
  content: '\e61c';
}
.icon-pizza:before {
  content: '\e61d';
}
.icon-cocktail:before {
  content: '\e61e';
}
.icon-doughnut:before {
  content: '\e61f';
}
.icon-burger:before {
  content: '\e620';
}
.icon-coffee:before {
  content: '\e621';
}
.icon-chicken:before {
  content: '\e622';
}
.icon-minimize:before {
  content: '\e623';
}
.icon-maximize:before {
  content: '\e624';
}
.icon-stop:before {
  content: '\e625';
}
.icon-forwards:before {
  content: '\e626';
}
.icon-backwards:before {
  content: '\e627';
}
.icon-pause:before {
  content: '\e628';
}
.icon-play:before {
  content: '\e629';
}
.icon-webcam:before {
  content: '\e62a';
}
.icon-cc:before {
  content: '\e62b';
}
.icon-hd:before {
  content: '\e62c';
}
.icon-bell:before {
  content: '\e62d';
}
.icon-playlist:before {
  content: '\e62e';
}
.icon-refresh:before {
  content: '\e62f';
}
.icon-random:before {
  content: '\e630';
}
.icon-mic:before {
  content: '\e631';
}
.icon-volumeoff:before {
  content: '\e632';
}
.icon-volumeon:before {
  content: '\e633';
}
.icon-equalizer:before {
  content: '\e634';
}
.icon-video:before {
  content: '\e635';
}
.icon-headphones:before {
  content: '\e636';
}
.icon-music:before {
  content: '\e637';
}
.icon-ring:before {
  content: '\e638';
}
.icon-diamond:before {
  content: '\e639';
}
.icon-crown:before {
  content: '\e63a';
}
.icon-cup:before {
  content: '\e63b';
}
.icon-medal:before {
  content: '\e63c';
}
.icon-flash:before {
  content: '\e63d';
}
.icon-rocket:before {
  content: '\e63e';
}
.icon-hanger:before {
  content: '\e63f';
}
.icon-pants:before {
  content: '\e640';
}
.icon-glasses:before {
  content: '\e641';
}
.icon-sox:before {
  content: '\e642';
}
.icon-hat:before {
  content: '\e643';
}
.icon-tie:before {
  content: '\e644';
}
.icon-tshirt:before {
  content: '\e645';
}
.icon-home:before {
  content: '\e646';
}
.icon-confirm:before {
  content: '\e647';
}
.icon-delete:before {
  content: '\e648';
}
.icon-plus:before {
  content: '\e649';
}
.icon-next:before {
  content: '\e64a';
}
.icon-back:before {
  content: '\e64b';
}
.icon-list:before {
  content: '\e64c';
}
.icon-menu:before {
  content: '\e64d';
}
.icon-diagram:before {
  content: '\e64e';
}
.icon-eye:before {
  content: '\e64f';
}
.icon-download:before {
  content: '\e650';
}
.icon-upload:before {
  content: '\e651';
}
.icon-compass:before {
  content: '\e652';
}
.icon-rating:before {
  content: '\e653';
}
.icon-notepad:before {
  content: '\e654';
}
.icon-document:before {
  content: '\e655';
}
.icon-settings:before {
  content: '\e656';
}
.icon-boy:before {
  content: '\e657';
}
.icon-girl:before {
  content: '\e658';
}
.icon-folder:before {
  content: '\e659';
}
.icon-group:before {
  content: '\e65a';
}
.icon-pin:before {
  content: '\e65b';
}
.icon-smile:before {
  content: '\e65c';
}
.icon-send:before {
  content: '\e65d';
}
.icon-timer:before {
  content: '\e65e';
}
.icon-edit:before {
  content: '\e65f';
}
.icon-cart:before {
  content: '\e660';
}
.icon-key:before {
  content: '\e661';
}
.icon-share:before {
  content: '\e662';
}
.icon-lock:before {
  content: '\e663';
}
.icon-unlock:before {
  content: '\e664';
}
.icon-present:before {
  content: '\e665';
}
.icon-message:before {
  content: '\e666';
}
.icon-about:before {
  content: '\e667';
}
.icon-flag:before {
  content: '\e668';
}
.icon-exit:before {
  content: '\e669';
}
.icon-lamp:before {
  content: '\e66a';
}
.icon-calendar:before {
  content: '\e66b';
}
.icon-search:before {
  content: '\e66c';
}
.icon-star:before {
  content: '\e66d';
}
.icon-heart:before {
  content: '\e66e';
}
.icon-unlike:before {
  content: '\e66f';
}
.icon-like:before {
  content: '\e670';
}
