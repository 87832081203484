@use 'sass:math';
.references {
  @include font-size(.9rem);
  opacity: .8;

  a {
    color: inherit;
    text-decoration: underline;
  }

  li {
    margin-bottom: math.div($spacer, 2);
  }
}
