@use 'sass:math';
body.dev-mode:before {
  content: '';
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: url('dev-mode.svg') repeat-x 21px 0;
  cursor: help;
}

.dev-tools {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;

  .dev-tool-button {
    margin-top: math.div($spacer, 2);
  }
}

.dev-tool-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #000;
  color: $white !important;
  cursor: pointer;

  opacity: .8;
  box-shadow: $box-shadow;
  transition: all .15s ease-in-out;

  &:hover {
    color: $white;
    text-decoration: none;

    opacity: 1;
    box-shadow: $box-shadow-lg;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
  }
}
