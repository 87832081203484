.site-navbar-container {
  position: relative;
  z-index: 10; // show in front of backgrounds

  transition: $transition-base;  
}

.site-navbar {
  .nav-item {
    font-weight: $font-weight-bold;
    @include font-size($font-size-sm);

    .btn {
      @include font-size($font-size-sm);
    }

    &.active {
      
      .nav-link {
        position: relative;

          &:after {
            
            @include media-breakpoint-up(lg) {
              content: "";
              position: absolute;
              left: 20px;
              right: 20px;
              height: 2px;
              bottom: 0px;
              background: $primary;
            }
          }
      }
    }
    
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      @include font-size(1.1rem);

      .nav-item + .nav-item {
        border-top: 1px solid transparentize($black, .85);
      }

      .dropdown-toggle {
        font-weight: $font-weight-bold;
        @include font-size($font-size-sm);
        color: $white;
        pointer-events: none;

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        display: block;
        padding-top: 0;
        margin: 0;
        background: transparent;
        border: none;
        font-size: inherit;
      }

      .dropdown-item {
        padding: $nav-link-padding-y 0;
        color: $navbar-dark-color;

        &:hover, &:focus {
          background: transparent !important;
          color: $navbar-dark-hover-color;
        }
      }
    }
  }
}
