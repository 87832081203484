.blob-icon {
  position: relative;
  width: 45vw;
  max-width: 350px;
  left: 0% !important;
  
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    font-size: 64px;

    @include media-breakpoint-up(md) {
      font-size: 96px;
    }
  }

  &-sm {
    width: 150px;

    .icon {
      font-size: 48px;
    }
  }
}

.blob-1 {
  fill: theme-color(alt-2);
  fill-opacity: .3;
}

.blob-2 {
  fill: theme-color($primary);
  fill-opacity: .2;
}

@each $color, $value in $theme-colors {
  @include fill-color-variant(".fill-#{$color} .blob-2", $value);
  @include fill-color-variant(".fill-#{$color} .blob-1", cal($value, 25%));
}
