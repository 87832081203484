.cta {
  position: relative;
  background: $primary linear-gradient(90deg, $primary, adjust_hue($primary, 60deg));
  color: $white;
  overflow: hidden;

  .blobs {
    position: absolute;
    transform: translateX(50%) translateY(-35%);

    .blob-1, .blob-2 {
      fill: $white;
      fill-opacity: .1;
    }
  }
}

.cta-content {
  position: relative;
  z-index: 2;

  .form-group {
    /* so invalid tooltips position correctly */
    position: relative;
  }

  .form-control,
  .input-group-text {
    border-width: 0;
  }

  .form-control:focus {
    box-shadow: none !important;
  }

  .form-control-lg {
    /* so borders are correctly rounded in input groups with an invalid tooltip */
    border-top-right-radius: $input-border-radius-lg !important;
    border-bottom-right-radius: $input-border-radius-lg !important;
  }
}

.cta-img {
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%); 
}

.btn-primary {
  @include button-variant($primary,$primary,#4ED709,#4ED709);
  color: #004D00;
}

//overwrite mailer form design to match custom font
.ml-form-embedContent p {
  font-family: $font-family-sans-serif !important;
  @include font-size($font-size-sm);
}

.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .custom-control-label,
.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody p {
  font-family: $font-family-sans-serif !important;
}
//only change padding for contact form due to white bg
.contact-form {
  .ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody {
    padding: 0 !important;
  }
}