@use 'sass:math';


.article-body {
  @include rich-text();
}

// Article image block

.article-image {
  display: block;
  margin: $spacer*3 auto $spacer*3 auto;
  height: auto;
  max-width: 100%;
}

.article-phone-screens {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spacer;
  background: $gray-200;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

// Article phone screen block

.article-phone-screen {
  width: 300px;
  max-width: 100%;

  & + & {
    margin-top: $spacer;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .article-phone-screen-inner {
    position: relative;
    background: #000 linear-gradient(to right,
      #666 0%,
      #888 1%,
      #888 2%,
      #111111 2%,
      #111111 98%,
      #888 98%,
      #888 99%,
      #666 100%
    );
    padding: 0 6%;

    &:before, &:after {
      content: '';
      position: absolute;
      right: -1%;
      width: 1%;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background: #666;
    }

    &:before {
      top: 10%;
      height: 10%;
    }

    &:after {
      top: 30%;
      height: 14%;
    }
  }

  img, video {
    display: block;
    width: 100%;
    height: auto;
  }
}

// Article quote block (used for both quotes & testimonials)

.article-quote {
  padding-left: calc(#{$spacer * 2} - 4px);
  border-left: 4px solid theme-color(secondary);

  &__quote {
    @extend .lead;
    margin-bottom: math.div($spacer, 4);
    font-style: italic;

    p:first-child::before {
      content: open-quote;
    }

    p:last-child::after {
      content: close-quote;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: $spacer * -2;
  }
}
